import React, { useEffect, useState, createContext } from 'react';
import axios from 'axios';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider, useTheme } from '@material-ui/core';
import { PrivateRoute } from './components/private-route';

import { Reports }  from './components/modules/reports';


export const DialogContext = createContext({});
export const TranslationContext = createContext({});

function App() {
  const theme = useTheme();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });
  
  // const params = new URLSearchParams(window.location.pathname);

  const [translations, setTranslations] = useState({});
  const [langCode, setLangCode] = useState("mk");
  const [userInfo, setUserInfo] = useState({});
  const [selectedModule, setSelectedModule] = useState("/reports")
  const [publicSerialNumber, setPublicSerialNumber] = useState("")
  const apiPath = "";

  useEffect(() => {
    async function fetchTranslations() {
      const savedLangCode = localStorage.getItem("langCode")
      let langCodeToSend = "mk"
      savedLangCode === null ? langCodeToSend = langCode : langCodeToSend = savedLangCode;

      const token = localStorage.getItem("token");
      const response = await axios.get(`${apiPath}fetchTranslations.php?langCode=${langCodeToSend}`, { headers: { Authorization: `Bearer ${token}` } });
      setTranslations(response.data.translations);
    }
    
    fetchTranslations();
  }, [langCode]);

  // resava problem ako korisnikot ne navigira preku Router (in-app) tuku so direkten link
  useEffect(() => {
    const savedLangCode = localStorage.getItem("langCode")
    savedLangCode === null ? setLangCode("mk") : setLangCode(savedLangCode);
    setPublicSerialNumber((new URL(window.location.href)).searchParams.get("serialNumber") || "")
  }, [])

  return (
    <React.Fragment>
      <div className='App'>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <TranslationContext.Provider
              value={{
                langCode,
                setLangCode,
                translations,
                setTranslations,
              }}
            >
              {/* Imeto DialogContext ne e reprezentativno voopsto, sedi od istoriski pricini (setSuccessZero e Dialog Box) */}
              <DialogContext.Provider
                value={{
                  apiPath,
                  setUserInfo,
                  userInfo,
                  selectedModule,
                  setSelectedModule,
                  publicSerialNumber,
                }}
              >
                  <Router basename={process.env.PUBLIC_URL}>  
                    <PrivateRoute exact={true} path='/' component={Reports} />
                    <PrivateRoute exact={true} path='/reports' component={Reports} />
                  </Router>
              </DialogContext.Provider>
            </TranslationContext.Provider>
          </QueryClientProvider>
        </ThemeProvider>
      </div>
    </React.Fragment>
  );
}

export default App;
