import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import { makeStyles, createStyles} from '@material-ui/core';
import { TranslationContext } from './../../App';
import {DialogContext} from './../../App'
import {CircleIndicator} from  './../C-reports/card';
import {Graph} from './../C-reports/graph';
import RefreshIcon from '@material-ui/icons/Refresh';
import {ToggleSwitch} from "./../C-reports/toggle-switch";

const SPLIT_CARD_GRAPH = 900
const SPLIT_DROPDOWN = 700;
const DEFAULT_ZOLT = 800;
const DEFAULT_CRVEN = 1200;

const setScatterData = (sensorData) => {
    return sensorData
      ?.reverse()
      .map((sensor, index) => ({
        valueY: `${Number(sensor.CO2)}`,
        valueY2: `${Number(sensor.Tem)}`,
        Time: new Date(sensor.t.replace(/-/g, "/")).getTime(),
    }));
}

const useStyles = makeStyles(({ breakpoints }) =>
  createStyles({
    circleContainer : {
      float : "left",
      display : "inline-block",
      [breakpoints.down(SPLIT_CARD_GRAPH)]: {
        float : "none",
        width : "320px",
        display: "block",
        margin : "auto",
        marginTop : "30px",
        marginBottom : "30px",
        transform : "scale(0.9)"
      },
      [breakpoints.down(SPLIT_DROPDOWN)] : {
        display : "block",
        margin : "auto",
        transform : "scale(0.7)",
        marginTop : "-10px",
        marginBottom : "0px"
      },
    },
    graphContainer: {
      float : "left", 
      marginTop : "50px",
      width : "calc(100% - 350px)",
      [breakpoints.down(SPLIT_CARD_GRAPH)]: {
        width : "90%",
        margin : "0 10px",
        float : "none",
        boxSizing : "border-box",
        marginBottom : "80px"
      },
    },
    refreshIcon : {
      float : "right", 
      display : "inline-block",
      width : "30px",
      height : "30px",
      position : "relative",
      top : "20px",
      right : "10px",
      cursor : "pointer",
      userSelect : "none"
    }
  })
);

export const Reports = () => {
    const { translations } = useContext(TranslationContext);
    const {apiPath, publicSerialNumber} = useContext(DialogContext)
    const classes = useStyles();
  
    const [ isPublic, setIsPublic] = useState(true)

    const [ deviceState, setDeviceState ] = useState(undefined)
    const [ pragCrven, setPragCrven ] = useState(DEFAULT_CRVEN);
    const [ pragZolt, setPragZolt ] = useState(DEFAULT_ZOLT);
    
    const [ sensorData, setSensorData ] = useState([]);
    const [allowRefetch, setAllowRefetch] = useState(true)

    const [currentGraph, setCurrentGraph] = useState("CO2")

    useEffect(() => {
      if(publicSerialNumber === '')
        return
      
      setTimeout(() => {
        refetchDeviceData.refetch()
        refetchDeviceState.refetch()
      }, 200)
    }, [publicSerialNumber])

    const refetchDeviceData = useQuery('sensor-data', async () => {
        try {
            const response = await axios.get(`${apiPath}getDeviceData.php?serialNumber=` + publicSerialNumber + '&interval=1');

            if(response.data.success){
              setIsPublic(true)
              setSensorData(setScatterData(response.data.records));
            }
            else if(response.data.success === 0){
              if(response.data.status === 403)
                setIsPublic(false)
            }

            return response.data.record;
        } catch (err) {
        }
    });
    
    const refetchDeviceState = useQuery('device-state', async () => {
      try {
        const response = await axios.get(`${apiPath}deviceState.php?serialNumber=` + publicSerialNumber);
        
        if(response.data.success){
          setIsPublic(true)
          setDeviceState(response?.data?.data)
          setPragCrven(response?.data?.data?.pragCrven);
          setPragZolt(response?.data?.data?.pragZolt);
        }
        else if(response.data.success === 0){
          if(response.data.status === 403)
            setIsPublic(false)
        }

        return response.data.data;
      } catch (err) {
      }
    });

    const refetchDevice = (refresh=false) => {
      if(allowRefetch || refresh){
        refetchDeviceData.refetch()
        refetchDeviceState.refetch()
      }
      setAllowRefetch(false)
    }

    if(isPublic === false)
      return (
        <div style={{textAlign : "center", marginTop : "50px"}}>
          {translations?.authentication?.deviceNotPublic}
        </div>
      )

    return (
      <>
        <div className={classes.refreshIcon}>
          <RefreshIcon onClick={() => {refetchDevice(true)}} />
        </div>
        <div>
          <ToggleSwitch setCurrentGraph={setCurrentGraph} currentGraph={currentGraph}/>

          <div className={classes.circleContainer}>
            <CircleIndicator deviceState={deviceState} currentGraph={currentGraph} />
          </div>

          <div className={classes.graphContainer} >
              <Graph 
                currentGraph={currentGraph}
                sensorData={sensorData}
                interval={1}
                pragCrven={pragCrven}
                pragZolt={pragZolt}
              />
          </div>
        </div>
      </>
    )
}
