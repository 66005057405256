import { createStyles, makeStyles,} from '@material-ui/core';
import backgroundImage from './styles/footer30.jpg';

const useStyles = makeStyles(() =>
  createStyles({
    footer : {
        position : "fixed",
        bottom : "0",
        width : "100%",
        height : "30px",
        borderTop : "1px solid black",
        textAlign : "left",
        paddingLeft : "20px",
        boxSizing : "border-box",
        // backgroundColor : "#002057",
        lineHeight: "30px",
        fontSize : "0.7em",
        color : "white",
        zIndex : "105",
    },
  })
);

export const Footer = () => {
    const classes = useStyles();

    return (
      <footer className={classes.footer} style={{ backgroundImage: `url(${backgroundImage})` }}>
        &copy; DATAMAJORIS &nbsp;{new Date().getFullYear()}
      </footer>
    );
}
