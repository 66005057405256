import React, { useContext} from 'react';
import {
  createStyles,
  makeStyles,
} from '@material-ui/core';
import  './styles/globalClasses.css';
import { TranslationContext } from '../App';
import backgroundImage from './styles/header60.jpg'

const useStyles = makeStyles(({ breakpoints }) =>
  createStyles({
    header: {
      height : "60px"
    },
    imgLogo : {
      position : "absolute",
      left : "10px",
      top : "10px",
      cursor : "pointer",
      width : "200px",
      height : "40px",
      zIndex : "105",
      [breakpoints.down(800)]: {
        top : "10px",
        width : "135px",
        height : "27px"
      },
    },
    damaLuft : {
      fontFamily : "futura_bk_btbook",
      textAlign : "center",
      position : "relative",
      top : "10px",
      margin : "auto",
      color : "white",
      marginTop : "0px",
      marginBottom : "0px",
      padding : "0px",
      transform: "scale(0.95)", 
      [breakpoints.down(800)]: {
        display : "inline-block",
        top : "35px",
        left : "12px",
        fontSize : "1.25em",
        transform: "scale(0.85)",
      },
    },
    headerJazik : {
      cursor : "pointer",
      position : "absolute",
      top : "17px",
      height : "20px",
      padding : "0px",
      margin : "0px",
      right : "30px",
      color : "white",
      transform: "scale(0.9)",
    },
  })
);

export const Header = () => {
  const classes = useStyles();
  const {langCode, setLangCode, translations} = useContext(TranslationContext);

  const handleSetLangCode = () => {
    // Loop : mk - en - sr - mk
    let newLangCode;
    if(langCode === "mk") newLangCode = "en"
    if(langCode === "en") newLangCode = "sr"
    if(langCode === "sr") newLangCode = "mk"

    //localStorage.setItem("langCode", langCode === "mk" ? "en" : "mk")
    localStorage.setItem("langCode", newLangCode)
    // langCode === "mk" ? setLangCode("en") : setLangCode("mk")
    setLangCode(newLangCode)
  }

  if(typeof(translations) === "undefined" || Object.keys(translations).length === 0)
    return <React.Fragment></React.Fragment>

  return (
    <header className={classes.header} style={{backgroundImage : `url(${backgroundImage})`}}>
        <img src={`../../logo.png`} alt='DataMajoris logo' className={classes.imgLogo}/>
        <h1 className={classes.damaLuft}>DamaLUFT</h1>  

        <h4 className={classes.headerJazik} onClick={() => {handleSetLangCode()}}>
                {langCode.toUpperCase()}
        </h4>
    </header>
  );
};
