import "./toggle-switch.css";

export const ToggleSwitch = (props) => {
    return (
        <div className="container-toggle">
            <div style={{width : "170px", height : "15px", margin : "auto"}}>
                <div style={{
                    position : "relative",
                    width: "55px",
                    display: "inline-block",
                    textAlign: "center",
                    top: "18px",
                    fontWeight : "700",
                    color : "#2974ab",
                }}>
                    CO2
                </div>
                <div className="toggle-switch-div">
                    <input type="checkbox" className="checkbox-toggle" name={"toggle-bar"} id={"toggle-bar"} />
                    <label className="label-toggle" htmlFor={"toggle-bar"}  
                        onClick={() => {
                            let newCurrentGraph = props.currentGraph === "CO2" ? "T" : "CO2"
                            props.setCurrentGraph(newCurrentGraph);
                        }}
                    >
                        <span className="inner-toggle" />
                        <span className="toggle-switch-span" />
                    </label>
                </div>
                <div style={{
                    position : "relative",
                    width: "55px",
                    display: "inline-block",
                    textAlign: "center",
                    top: "18px",
                    fontWeight : "700",
                    color : "#5ca9e0",
                }}>
                    T
                </div>
            </div>
        </div>
    );
};
